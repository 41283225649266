import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const AsbestosPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "Asbestos Removal Services",
      "alternateName": "asbestos remediation services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
        "@type": "GeoCircle",
        "geoMidpoint": {
          "@type": "GeoCoordinates",
          "latitude": ${data.geoLon}
          "longitude": ${data.geoLat}
        },
          "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Asbestos Removal Services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "Asbestos Removal Services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Asbestos Removal Services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Asbestos Inspection Services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Asbestos Abatement Services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "asbestos remediation services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Asbestos Abatement, Removal & Inspection Services"
        description="We provide licenced and insured asbestos abatement, asbestos removal & asbestos inspection services."
        canonical="https://ecotechrestoration.ca/services/asbestos-removal/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />

      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/asbestos.jpg"
          quality={95}
          alt="asbestos removal Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Asbestos Removal Services
        </h1>
      </div>
      <SiteWidthWrapper>
        <CallOut>Do you have an Asbestos Abatement Emergency?</CallOut>
        <p className="services__alertP">
          Any home or structure built before 1990 should be inspected by an
          Environmental Consultant to prevent asbestos exposure.
        </p>
        <h2>
          Ecotech Restoration Is A Licensed & Certified Asbestos Removal
          Contractor
        </h2>
        <p>
          Ecotech Restoration is a professional Asbestos Removal & Remediation
          cleaning company that will remove asbestos from your home or business.
        </p>
        <p>
          We will work with you & your insurance provider and abide by the local
          bylaws to ensure that your building is free of any asbestos if we find
          any trace of asbestos on your property.
        </p>
        <h2>Asbestos Inspection & Testing Services</h2>
        <p>
          We provide professional asbestos inspection and testing services for
          your home or business. If asbestos is found, we will provide a
          detailed report outlining the asbestos type, quantity, and location.
        </p>
        <p>
          We will also recommend the best course of action to remove the
          asbestos and prevent future exposure.
        </p>
        <SplashTwo title="Asbestos Removal" />
        <h2>Asbestos FAQ</h2>
        <h3>What Are The Dangers of Asbestos?</h3>
        <p>
          Asbestos is dangerous because it's a carcinogen. Inhaling or ingesting
          asbestos is linked to health problems, including mesothelioma &
          asbestosis.
        </p>
        <p>
          Did you know that over 3,000 people per year in the U.S alone are
          diagnosed with mesothelioma cancer, with 10,000 people per year dying
          from asbestos lung cancer?{' '}
          <cite>https://www.asbestos.com/mesothelioma/statistics/</cite>.
        </p>
        <h3>Is Asbestos Dangerous to Me, My Family, or Pets?</h3>
        <p>
          Yes. It is a carcinogen that can cause cancer, among other health
          problems.
        </p>
        <h3>What does Asbestos Abatement Mean?</h3>
        <p>
          Asbestos Abatement means removing asbestos from your home or workplace
          to prevent exposure and reduce the risk of you or someone you love
          developing asbestos-related cancer. This typically involves a thorough
          asbestos inspection and asbestos removal and cleanup once the asbestos
          is found. Local bylaws govern this entire process to ensure the
          asbestos is removed and disposed of properly.
        </p>
        <h3>What Percentage of Asbestos Requires Abatement?</h3>
        <p>
          There is no legal limit on the amount of asbestos present in a home or
          workplace. Still, we generally recommend removing any friable asbestos
          (able to be crumbled by hand).
        </p>
        <h3>When Is Asbestos Abatement Required?</h3>
        <p>
          Asbestos abatement is required when found in your home or workplace,
          and the asbestos is friable (it can be crumbled by hand).
        </p>
        <h3>How Much Does an Asbestos Abatement Cost?</h3>
        <p>
          The cost of asbestos abatement varies depending on the size and scope
          of the project. Generally, the larger the area that needs to be
          remediated, the higher the cost.
        </p>
        <h3>How Long Does Asbestos Abatement Take?</h3>
        <p>
          The time it takes to complete asbestos abatement depends on the size
          and scope of the project. Typically, an asbestos removal team will be
          able to safely remove asbestos within a few days. However, if asbestos
          must be removed in a wider area or is particularly difficult to
          access, it can take longer to complete.
        </p>
        <h3>Why Was Asbestos Commonly Used If It's So Dangerous?</h3>
        <p>
          First, the mere presence of asbestos in your home or building is not
          hazardous. The danger is when it's disturbed or damaged. Given how
          safe it is when left undisturbed, it was used in many building
          products because products containing it are highly resistant to fire,
          doesn't wear down or decay if left undisturbed, and is considered a
          stable material.
        </p>
        <p>
          The take-away: leave products possibly containing asbestos alone and
          hire a professional.
        </p>
        <h3>Where Is Asbestos Commonly Found?</h3>
        <p>
          Many buildings built before 1980, and especially buildings & houses
          built between1930 - 1950, may have asbestos in their:
          <ul>
            <li>vinyl floor tiling</li>
            <li>boiler insulation</li>
            <li>fireplace insulation</li>
            <li>plumbing fixtures</li>
            <li>pipe or duct coverings</li>
            <li>drywall mud</li>
            <li>drywall sheets</li>
            <li>cement sheets</li>
            <li>electrical switchboard panels</li>
            <li>popcorn ceilings</li>
            <li>plaster, putty or caulking</li>
            <li>roof shingles</li>
            <li>siding</li>
            <li>vermiculite insulation</li>
            <li>ceiling tiles & coatings</li>
          </ul>
        </p>
        <h3>Why Is Asbestos Abatement Important?</h3>
        <p>
          Improper asbestos removal endangers your life and the lives of
          everyone who comes into contact with asbestos dust, fibres, or its raw
          material.
        </p>
        <h3>Are There Different Kinds Of Asbestos Abatements?</h3>
        <p>
          Yes. Asbestos abatement is classified into three kinds:
          <br />
          <br />
          <bold>Class I asbestos work:</bold> Activities involving the removal
          of asbestos used to prevent heat loss or gain, surfacing
          asbestos-containing materials and those suspected of containing
          asbestos.
          <br />
          <br />
          <bold>Class II asbestos work:</bold> Removal of non-thermal
          asbestos-containing materials such as wallboards, floor tiles and
          sheeting, roofing and siding shingles and construction mastics.
          <br />
          <br />
          <bold>Class III asbestos work:</bold> Repair and maintenance
          operations of any asbestos-containing materials that will likely be
          disturbed. This is the Class is the most common when removing
          asbestos.
        </p>
      </SiteWidthWrapper>
    </Layout>
  )
}

export default AsbestosPage
